@import "styles/_variables.scss";
body #root > .content {
  flex: 1 0 0;
  margin-top: 100px;
  position: relative;
  @media only screen and (max-width: $breakpoint) {
    margin-top: 0;
    overflow: auto;
  }
}
