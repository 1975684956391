@import "styles/_variables.scss";
@import "styles/_mixins.scss";

.profile_page {
  .header-image {
    background-position-x: 82%;
    > .block-container {
      position: relative;
    }
  }
  .logout {
    position: absolute;
    right: 20px;
    padding: 10px;
  }
  .tabbed-card {
    max-width: 400px;
  }
  .small-container {
    max-width: 820px;
    margin: auto;
  }
  .tabbed-card > * > *:nth-last-child(2) {
    flex: 1;
  }
  .switch_container {
    padding: 8px 0;
    display: flex;
    border-bottom: solid 1px #ccc;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: baseline;
    button {
      box-shadow: none;
    }
  }
  .sn-btns {
    padding: 10px 0;
    gap: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .sn-accounts {
    .switch_container {
      margin-bottom: 8px;
    }
    margin-top: 20px;
  }
  .sn-account {
    img {
      border-radius: 50%;
      width: 30px;
      margin: 0 10px;
    }
  }

  @media only screen and (min-width: #{$breakpoint+1px}) {
    .tabbed > .panels {
      display: grid;
      grid-template-columns: 1fr 1fr;
      > *:first-child {
        grid-row: 1 / span 2;
      }
    }
  }
  @media only screen and (max-width: $breakpoint) {
    .tabbed-card > div .title {
      display: none;
    }
  }
}
