@import "styles/_variables.scss";

.common_page {
  margin-bottom: 30px;
  .header-image {
    margin-top: -50px;
    height: #{124px+50px+20px};
    background-color: rgb(88, 197, 254);
    background-position-y: bottom;
    background-repeat: no-repeat;
    padding-top: 70px;
    h1 {
      font-size: 48px;
      color: white;
      font-weight: 200;
      text-shadow: 0 0 6px #00000057;
    }
  }
  .empty_space {
    height: 30px;
  }
  .contract_status {
    font-weight: 400;
    color: white;
    padding: 3px 10px;
    margin: 10px 0;
    &.span {
      margin: 3px 0;
      padding: 1px 7px;
      display: inline-block;
    }
  }

  @each $status, $color in $statusColor {
    .contract_#{$status} {
      background-color: $color;
    }
  }
  .group {
    font-weight: 500;
    border-bottom: 1px solid $sky;
    margin: 20px auto;
    padding: 0 0 3px;
  }
  h3.group {
    font-weight: 400;
    font-size: 20px;
  }

  .ant-form label {
    font-size: 0.8em;
    color: #aaa;
  }

  .ant-form .ant-input {
    height: auto;
    padding: 1px 8px;
  }
  .ant-form .ant-input,
  .ant-form span.ant-input-password {
    border-width: 0 0 1px !important;
  }
  .ant-form span.ant-input-password {
    padding: 0;
  }
  .ant-form-item-explain > div {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .tabbed .tabbed-card > div .title {
    justify-content: center;
    border: none;
    font-size: 16px;
    font-weight: 500;
    color: #1890fb;
    display: flex;
    padding-bottom: 20px;
    text-align: center;
  }
}
