@import "styles/_variables.scss";

.billing_page .payment_modes {
  overflow-x: hidden;
  .tabbed > .panels {
    margin-top: 30px;
  }
  .tabbed > .btns {
    display: none;
  }
  .card-btn {
    padding: 5px 0;
  }
  .tabbed-card > * > *:nth-last-child(2) {
    flex: 1;
  }
  .goto_plus {
    font-weight: 500;
    color: $green;
    text-align: center;
    cursor: pointer;
    margin: -20px 0 20px;
    &::before {
      content: "+";
      display: inline-flex;
      margin-right: 10px;
      font-size: 20px;
      vertical-align: -1px;
      border: solid 2px;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 0 0 3px;
    }
  }
  .tabbed-card-container {
    display: flex;
    flex-direction: column;
    flex: 1;
    .tabbed-card {
      height: auto;
      flex: 1;
      width: 100%;
    }
  }
  .empty_card, .tabbed-card {
    min-height: 406px;
  }
  .carousel_container {
    margin: 0 auto;
    max-width: 300px;
  }
  .payment_cards .card_container {
    margin: 0;
  }
  .card_container .remove_card {
    position: absolute;
    top: 0;
    right: -15px;
    width: 30px;
    height: 30px;
    border: solid 2px;
    color: $green;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 15px;
    background: white;
    font-size: 28px;
    padding-bottom: 5px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
  }
  .slick-slide:not(.slick-active) .card_container .remove_card {
    opacity: 0;
  }
  .payment_cards,
  .tabbed > .panels {
    display: flex;
    gap: 30px;
    justify-content: center;
    > div {
      max-width: 300px;
    }
  }
  .card_container {
    margin: 0 10px;
    border-radius: 10px;
    position: relative;
    padding-top: 14px;
    img {
      width: 100%;
    }

    .new_card {
      background: #ddedff;
      border-radius: 4%/6%;
      position: relative;
      .blue-btn {
        border-radius: 16px;
        flex: 1 1 auto;
        text-overflow: ellipsis;
        overflow: hidden;
        min-width: 80px;
      }
      > img {
        opacity: 0;
      }
      > div {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        left: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        padding: 0 10px;
        text-align: center;
        > div {
          display: flex;
          gap: 6px 10px;
          justify-content: center;
          flex-wrap: wrap;
          margin: 10px 0;
          &.only_btns {
            flex-direction: column;
            .blue-btn {
              align-self: center;
              &::before {
                content: "+ ";
              }
            }
          }
        }
        > span {
          font-weight: 500;
          font-size: 16px;
          flex-shrink: 1;
          overflow: hidden;
          margin-top: 10px;
        }
      }
    }
  }
  .horizontal_fields {
    display: flex;
    white-space: nowrap;
    gap: 0 20px;
    flex-wrap: wrap;
    > div {
      width: 0;
      min-width: 60px;
      flex: 1 1 auto;
    }
    > div label {
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }
}
