@import "styles/_variables.scss";

body {
  min-height: 100%;
  > #root {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    @media only screen and (max-width: $breakpoint) {
      height: 100%;
    }
    .block-container {
      max-width: 1080px;
      margin: auto;
      padding: 0 20px;
    }
    > .page-footer,
    > .page-header {
      .block-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      a:not(.skiplink), .link {
        color: $green;
        cursor: pointer;
        &:hover {
          color: $green_light;
        }
        &:active {
          color: $green_dark;
        }
      }
      img {
        max-height: 26px;
        margin: 12px;
      }
    }
    > .page-footer {
      @media only screen and (max-width: $breakpoint) {
        display: none;
      }
      background-color: $ngray;
      padding: 10px 0;
      box-shadow: 0 0 3px 5px #80808080;
      color: white;
      overflow: hidden;
      .sn-logos {
        display: flex;
        gap: 8px;
        img {
          margin: 0;
        }
      }
    }
  }
}
