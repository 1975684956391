@import "styles/_variables.scss";

.contract_item {
  margin: 20px 0;
  display: flex;
  gap: 40px;
  font-weight: 400;
  align-items: center;
  @media only screen and (max-width: $breakpoint) {
    display: block;
  }
  > img {
    flex: 1 1 50px;
    width: 70px;
    float: left;
    margin: 0 20px 10px 0;
  }
  > div {
    flex: 5 1 100px;
  }
  .contract_menu {
    height: 50px;
    margin: 10px 0 0;
    border: solid $grey_3;
    border-width: 1px 0;
    display: flex;
    clear: both;
    > div {
      flex: 1;
      border-right: solid 1px $grey_3;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > div:last-child {
      border-right: none;
    }
  }
}

.contracts_page {
  .header-image {
    background-position-x: 82%;
  }
  .small-container {
    max-width: 720px;
    margin: auto;
  }
}