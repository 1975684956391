@import "styles/_variables.scss";
@import "styles/_mixins.scss";

body #root .page-header {
  position: fixed;
  z-index: 2;
  @media only screen and (max-width: $breakpoint) {
    position: static;
    .block-container {
      padding: 0 0 0 8px;
    }
  }
  width: 100%;
  box-shadow: 0 0 4px 4px #80808080;
  @media only screen and (max-width: $breakpoint) {
    box-shadow: none;
  }
  b {
    font-weight: 500;
  }
  .top-menu {
    @media only screen and (max-width: $breakpoint) {
      display: none;
    }
    height: 50px;
    background-color: white;
    z-index: 1;
    @include green_link;
    .links {
      flex: 1 0 auto;
      justify-content: space-between;
      display: flex;
      > * {
        margin: 0 6px;
      }
    }
  }
  .top-bar {
    background-color: $ngray;
    height: 50px;
    color: white;
    transition: height linear 0.2s;
    > * > * {
      max-height: 100%;
      overflow: hidden;
      transition: opacity ease-in-out 0.2s;
    }
    @media only screen and (min-width: #{$breakpoint+1px}) {
      &.fadeout {
        height: 0;
        > * > * {
          opacity: 0;
        }
      }
    }
  }
  .divider {
    display: inline-block;
    height: 16px;
    vertical-align: -3px;
    border-left: solid 1px $green;
    margin: 0 5px;
  }
  .logo {
    width: 130px;
    overflow: visible;
    > img {
      margin: 0;
      height: 28px;
    }
  }
  .free {
    color: $green;
  }
  .price {
    align-self: self-start;
    max-height: 200%;
    img {
      margin: 0;
      height: 90px;
      max-height: none;
      z-index: 1;
      position: relative;
    }
  }
  .place {
    @media only screen and (max-width: $breakpoint) {
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
      flex-grow: 1;
      > span {
        text-align: right;
        display: inline-flex;
        height: 1.4em;
        overflow: hidden;
        align-items: flex-end;
      }
    }
  }
  .flag {
    display: none;
    color: $green;
    font-size: 21px;
    padding: 6px 15px 6px 0;
    flex-grow: 0;
    @media only screen and (max-width: $breakpoint) {
      display: block;
    }
  }
  .right-btns {
    padding: 0 60px 0 10px;
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    flex: 8 1;
    justify-content: flex-end;
    > * {
      flex: 0 1 120px;
    }
  }
}
