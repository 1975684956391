@import 'styles/_mixins.scss';
@import 'styles/_variables.scss';

.edit_property {
  overflow: auto;
  flex: 1;
  margin-right: 10px;

  h2 {
    @include page_title;
  }

  form {
    padding-bottom: 40px;
  }

  .delete_property_btn {
    border-color: red;
    color: red;
    margin-bottom: 40px;
  }
}
