.carousel_container .carousel {
  padding: 0 35px 30px;
  position: relative;
  overflow: hidden;
  margin: 0 -10px;
  user-select: none;
  &::before,
  &::after {
    content: "";
    display: block;
    height: 100%;
    width: 10px;
    position: absolute;
    top: 0;
  }
  &::before {
    background: linear-gradient(90deg, white, white, transparent);
    z-index: 1;
    left: 0;
  }
  &::after {
    background: linear-gradient(270deg, white, white, transparent);
    right: 0;
  }
  .ant-carousel {
    .slick-dots {
      margin-bottom: -36px;
    }

    .slick-list {
      overflow: visible;
    }
    .slick-dots li {
      background: #0005;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      button {
        height: 100%;
        background: none;
        opacity: 0;
      }
      &.slick-active {
        background: #000a;
      }
    }
  }
}

