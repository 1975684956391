@import 'styles/_mixins.scss';

.properties_page {
  .header-image {
    background-position-x: 82%;
  }
  .small-container {
    max-width: 600px;
    margin: auto;
  }

}