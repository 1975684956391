@import "styles/_variables.scss";

.bottom_bar_menu {
  height: 70px;
  background-color: $menu_color;
  bottom: 0px;
  left: 0px;
  align-content: center;
  justify-content: center;
  width: 100%;
  display: none;

  .bottom_bar_menu_button {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: initial;
    flex: 1;
    color: white;
    justify-content: center;
    cursor: pointer;
    transition: all 0.1s ease-in-out;

    > span {
      font-style: normal;
      font-weight: normal;
      font-size: 10px;
      line-height: 16px;
      &:first-child {
        font-size: 15px;
      }
    }

    &:hover {
      color: $menu_color;
      background-color: white;
    }
  }

  .bottom_bar_menu_button_selected {
    color: $menu_color;
    background-color: white;
  }
}

@media only screen and (max-width: $breakpoint) {
  .bottom_bar_menu {
    display: flex;
  }
}
