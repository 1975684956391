@import "styles/_variables.scss";

.home-page {
  .header-image {
    margin-top: -50px;
    height: 308px;
    background-position-x: 62%;
    padding-top: 70px;
    h1,
    h2 {
      color: white;
      font-weight: 200;
      text-shadow: 0 0 6px #00000057;
    }
    h1 {
      font-size: 48px;
      margin: 0;
    }
    h2 {
      margin-top: -10px;
      font-size: 32px;
    }
    @media only screen and (max-width: $breakpoint) {
      h1 {
        text-align: center;
        max-height: 1.5em;
        overflow: hidden;
      }
      h2 {
        display: none;
      }
    }
  }
  .home-main {
    padding: 10px 0;
    .home_contract {
      padding: 20px 0;
      font-size: 15px;
      @media only screen and (max-width: $breakpoint) {
        padding: 10px 0;
      }
      .status {
        color: $blue;
        font-weight: 500;
        @media only screen and (max-width: $breakpoint) {
          float: right;
          position: relative;
          top: -5px;
          padding: 0 10px 0 20px;
        }
      }
      .address {
        color: $black;
        font-weight: 200;
      }
    }
    .number {
      font-size: 14px;
      font-weight: 600;
      white-space: nowrap;
      margin-left: 4px;
    }
    .home_panel {
      flex: 1;
    }
    .home_faq {
      padding: 10px 0;
    }
    .home_payment {
      padding: 20px 0;
      > * {
        display: flex;
      }
      > * > * {
        display: flex;
        align-items: center;
        flex: 1;
        white-space: nowrap;
      }
      > * > *:last-child {
        justify-content: flex-end;
        font-weight: bold;
      }
      &.home_array {
        > * {
          padding: 0 10px;
          height: 30px;
        }
        > * > *:first-child {
          color: $black;
        }
        > *:nth-child(odd) {
          background-color: rgba($blue, 0.2);
        }
        > *:nth-child(even) {
          background-color: rgba($blue, 0.05);
        }
      }
    }
  }
}
