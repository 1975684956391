@import "styles/_variables.scss";

.billing_page .history {
  max-width: 600px;
  margin: auto;
  > .group {
    margin-bottom: 0;
  }
  table {
    width: 100%;
    margin: 0 0 40px;
  }
  tr {
    height: 50px;
    border-bottom: solid 1px rgba($sky, 0.2);
  }
  td {
    &:nth-child(1) {
      width: 45%;
    }

    &:nth-child(2) {
      width: 20%;
    }

    &:nth-child(3) {
      width: 35%;
    }
  }
}
