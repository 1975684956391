@import "styles/_variables.scss";

.tabbed-card {
  border-radius: 6px;
  flex: 1;
  border: solid 1px $grey_5;
  overflow: hidden;
  max-width: 340px;
  margin: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  > img {
    width: 102%;
    margin: -1%;
  }
  > div {
    padding: 25px;
    flex: 1;
    display: flex;
    flex-direction: column;
    @media only screen and (max-width: 990px) {
      padding: 15px;
    }
    @media only screen and (max-width: $breakpoint) {
      padding: 15px 20px 10px;
    }
    .title {
      color: $black;
      font-size: 18px;
      border-bottom: solid 1px $sky;
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }
  }
}
