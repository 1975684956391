@import "styles/_variables.scss";

.contracts_page .tasks {
  display: grid;
  gap: 30px;
  grid-template-columns: 1fr 1fr;
  padding-bottom: 30px;
  @media only screen and (max-width: $breakpoint) {
    padding-top: 30px;
    grid-template-columns: 1fr;
  }
  > div {
    display: flex;
    padding: 20px;
    border: solid 1px #ccc;
    border-radius: 5px;
    align-items: center;
    gap: 20px;
    > img {
      width: 80px;
      flex: 1;
      @media only screen and (max-width: $breakpoint) {
        flex: 0 1 auto;
      }
    }
    > div {
      flex: 2;
      h1 {
        font-size: 18px;
      }
    }
  }
}
