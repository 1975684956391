@import "styles/_variables.scss";

.toolbar {
  display: none;
  height: 40px;
  background-color: $green;
  color: white;
  box-shadow: 0 1px #00000024;
  z-index: 1;
  align-items: center;
  @media only screen and (max-width: $breakpoint) {
    display: flex;
  }
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  span {
    padding: 0 10px;
    flex: 0 0 60px;
    &:last-child {
      flex-shrink: 1;
    }
    &.title {
      flex: 1 0 auto;
    }
    .back {
      cursor: pointer;
      padding: 5px;
      font-size: 24px;
    }
  }
}
