.autocomplete_root {
  position: relative;
  flex: 1;

  input {
    width: 100%;
  }
}

.autocomplete_dropdown_container {
  position: absolute;
  z-index: 1;
  width: 100%;
  background-color: white;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  text-align: left;

  span {
    padding: 10px;
  }
}
