.paginator {
  > ul {
    display: flex;
    padding: 0;
    justify-content: center;
    gap: 10px;
    user-select: none;

    > li {
      display: flex;
      width: 30px;
      height: 30px;
      > a {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
      }
      &:not(.selected) > a {
        color: inherit;
      }
      &.selected > a {
        border: solid 2px;
      }
      &.break {
        width: 0px;
        > a {
          display: none;
        }
      }
    }
  }
}
