@media only screen and (min-width: 991px) {
  .hide-xl, .show-md, .show-lg {
    display: none !important;
  }
}
@media only screen and (max-width: 990px) and (min-width: #{$breakpoint+1px}) {
  .hide-lg, .show-md, .show-xl {
    display: none !important;
  }
}
@media only screen and (max-width: $breakpoint) {
  .hide-md, .show-lg, .show-xl {
    display: none !important;
  }
}
