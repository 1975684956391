
.success {
  color: $green !important;
}

.success_background {
  background-color: $green !important;
}

.neutral {
  color: $blue !important;
}

.neutral_background {
  background-color: $blue !important;
}

.error {
  color: $red !important;
}

.error_background {
  background-color: $red !important;
}

.disabled {
  color: $disabled_color !important;
}

.disabled_background {
  background: $disabled_color;
}

.btn-facebook {
  background: $facebook_color;
}
.btn-google {
  background: $google_color;
}

.facebook-fg {
  color: $facebook_color;
}
.google-fg {
  color: $google_color;
}
