@import "styles/_variables.scss";

.breadcrumbs {
  border-bottom: solid 1px $grey_2;
  padding: 20px 0;
  @media only screen and (max-width: $breakpoint) {
    display: none;
  }
  > .block-container {
    display: flex;
    gap: 5px;
    align-items: baseline;
    * {
      color: $grey_1;
      font-weight: 400;
      cursor: default;
    }
    a,
    .link {
      cursor: pointer;
      &:hover,
      &:active {
        color: $green;
      }
      &:hover {
        font-weight: 500;
      }
    }
  }
}
