.login {
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  > * {
    padding: 20px;
  }
  .center {
    display: flex;
    justify-content: center;
  }
  label.center {
    font-weight: 400;
    padding: 0 0 5px;
    font-size: 20px;
  }
  input {
    height: auto;
  }
  .sn-btn {
    display: flex;
    padding: 0 0 20px;
  }
}
