$black: #444444;
$grey_1: rgba(68, 68, 68, 0.5);
$grey_2: rgba(68, 68, 68, 0.3);
$grey_3: rgba(68, 68, 68, 0.2);
$grey_4: rgba(68, 68, 68, 0.1);
$grey_5: rgba(0, 0, 0, 0.12);
$ngray: #414143;

$blue: #1890fb;
$red: #ed1c24;
$sky: #43adfe;

$facebook_color: #3b5998;
$google_color: #dd4b39;

$statusColor: (
  done: #06590b,
  canceled: #646464,
  waiting: #f29d20,
  inProgress: #49bcff,
);


$menu_color: #444444;
$disabled_color: #c4c4c4;
$green: #55ac31;
$green_light: #66c83c;
$green_dark: #42792a;

$divider_color: #e5e5e5;

$breakpoint: 700px;
