@import "styles/_variables.scss";

.map_container {
  height: 450px;
  width: 100%;
  max-width: 600px;
  padding-bottom: 30px;
  margin-top: 20px;

  .pointer {
    color: $green;
    position: relative;
    font-size: 30px;
    right: 15px;
    bottom: 15px;
  }
}
