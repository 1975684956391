@import "styles/_variables.scss";
.tabbed {
  > .btns {
    display: none;
    &.shown {
      display: flex;
    }

    gap: 10px;
    padding: 10px 0 20px;
    > * {
      flex: 1 0 0;
      display: flex;
      border: solid 1px $grey_5;
      height: 30px;
      border-radius: 15px;
      cursor: pointer;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &.selected {
        color: white;
        background-color: $blue;
      }
    }
  }
  > .panels {
    display: flex;
    gap: 20px;
    > * {
      flex: 1 0 0;
      display: flex;
    }
  }
  @media only screen and (max-width: $breakpoint) {
    > .btns {
      display: flex;
    }
    > .panels {
      > * {
        display: none;
        &.selected {
          display: flex;
        }
      }
    }
  }
  > .panels.single_panel {
    > * {
      display: none;
      &.selected {
        display: flex;
      }
    }
  }
}
