@import "styles/_variables.scss";

@mixin page_title {
  color: $green;
  font-size: 43px;
  margin-top: 10px;

  &:after {
    display: block;
    content: "";
    width: 80%;
    margin-top: 26px;
    border-top: 1px solid $grey_3;
    width: 147px;
    height: 0px;
  }

  @media only screen and (max-width: $breakpoint) {
    font-size: 25px;
    &:after {
      display: none;
    }
  }
}

@mixin title_and_button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 15px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  align-items: center;
}

@mixin cards {
  .cards {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 10px;
    > div {
      cursor: pointer;
      padding: 2px;
      display: flex;
      flex-direction: row-reverse;
      &.selected_card {
        border: solid 2px $blue;
        border-radius: 10px;
        padding: 5px;
        position: relative;
        margin: -5px;
        &:after {
          content: "";
          display: block;
          border-radius: 5px;
          width: 10px;
          height: 10px;
          position: absolute;
          background: $blue;
          margin: -2px -3px 0 0;
        }
      }
    }
  }
}

@mixin green_link {
  a:not(.skiplink),
  .link {
    color: $ngray;
    font-weight: 500;
    &:hover,
    &:active,
    &.selected {
      color: $green;
    }
  }
}
