@import "~antd/dist/antd.css";
@import "styles/variables";
@import "styles/_colors.scss";
@import "styles/_responsive.scss";

body {
  padding: 0px;
  margin: 0px;
}

button {
  background: $blue;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: white;
  padding: 7px 19.84px;
  cursor: pointer;
  user-select: none;
}

input {
  height: 50px;
  padding: 5px;
  border: 1px solid $grey_4;
}

button.flat-btn {
  flex: 1;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  box-shadow: none;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 8px;
  color: #fff;
}

.outlined_button {
  color: $blue;
  background-color: white;
  font-style: normal;
  font-weight: 600;
  font-size: 8.96px;
  line-height: 12px;
  padding: 6px 9px;
  border: 1px solid $blue;
  transition: all 0.2s ease-in-out;
  min-width: 67, 88px;

  &:hover {
    background-color: $blue;
    color: white;
  }
}
.green-btn,
.blue-btn {
  color: white;
  cursor: pointer;
  border: solid 2px;
  border-radius: 4px;
  padding: 3px 6px;
  display: inline-block;
  text-align: center;
  transition: all 0.2s ease-in-out;
  line-height: inherit;
  box-shadow: none;
  &:hover {
    background-color: white;
  }
}
.blue-btn {
  &:hover {
    color: $blue;
  }
  background-color: $blue;
  border-color: $blue;
}
.green-btn {
  &:hover {
    color: $green;
  }
  background-color: $green;
  border-color: $green;
}

.home_item {
  line-height: 23px;
  &::before {
    content: "›";
    color: #55ac31;
    margin-top: -3px;
    font-weight: 600;
    font-size: 25px;
    float: left;
    padding-right: 4px;
  }
}

.card-btn {
  display: flex;
  justify-content: center;
  > * {
    cursor: pointer;
    background-color: $red;
    height: 30px;
    border-radius: 15px;
    padding: 0 20px;
    display: flex;
    color: white;
    align-items: center;
    justify-content: center;
  }
  &.selected > * {
    color: $red;
    background: white;
    cursor: default;
  }
}
