@import "styles/_variables.scss";

.custom_input {
  display: flex;
  height: 70px;
  margin: 5px 0px;
  position: relative;
  flex-direction: column;
  align-items: flex-start;

  .input_number {
    flex: 1;
  }

  input {
    max-width: 400px;
    height: 35px;
    padding: 5px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
  }

  label {
    width: 200px;
    text-align: left;
    margin-bottom: 7px;
    color: $black;
    font-size: 17px;
  }

  .options {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
  }

  .option_container {
    display: flex;
    align-items: center;

    label {
      width: inherit;
      margin-right: 10px;
    }

    input[type="checkbox"] {
      margin-top: 5px;
    }
  }

  .selector {
    flex: 1;
    text-align: left;
  }

  .comment {
    position: absolute;
    left: 205px;
    top: 39px;
    font-size: 14px;
  }

  .autocomplete_root {
    width: 100%;
    max-width: 400px;
  }
}
