@import "styles/_variables.scss";

.billing_page {
  .header-image {
    background-position-x: 82%;
  }
  .small-container {
    max-width: 400px;
    margin: auto;
  }
}

